import { ReactNode } from 'react'

import { ChevronDownIcon } from '@nx/fire/assets'
import { FireCarousel } from '@nx/fire/carousel'
import { stripTags } from '@nx/helpers'
import { getTranslate } from '@nx/translations'

import {
  CarouselItemContainer,
  CarouselItemContentContainer,
  CarouselItemImageContainer,
  HeroImageCarouselContainer,
  ScrollToBottomContainer,
  StyledLargeImage,
  StyledSmallImage,
} from './hero-image-carousel.styles'
import translate from './hero-image-carousel.translate.json'

const { text } = getTranslate(translate)

export const HeroImageCarousel = ({
  slides,
  scrollToContentId,
}: HeroImageCarouselProps) => (
  <HeroImageCarouselContainer>
    <FireCarousel fullWidthItems="always" autoScroll={6000} alignment="start">
      {slides.map((slide, index) => (
        <div key={slide.id}>
          <Slide {...slide} index={index} />
        </div>
      ))}
    </FireCarousel>

    {scrollToContentId && (
      <ScrollToBottomContainer>
        <a href={`#${scrollToContentId}`}>
          <ChevronDownIcon height={30} title={text('jumpto')} />
        </a>
      </ScrollToBottomContainer>
    )}
  </HeroImageCarouselContainer>
)

const Slide = ({
  largeImage,
  smallImage,
  title,
  infoBox,
  index,
}: HeroImageCarouselItem & { index: number }) => (
  <CarouselItemContainer>
    <CarouselItemImageContainer>
      {smallImage && (
        <StyledSmallImage
          src={smallImage}
          alt={stripTags(title || '').trim()}
          fill
          priority={index === 0}
        />
      )}
      {largeImage && (
        <StyledLargeImage
          src={largeImage}
          alt={stripTags(title || '').trim()}
          fill
          priority={index === 0}
        />
      )}
    </CarouselItemImageContainer>

    {infoBox && (
      <CarouselItemContentContainer>{infoBox}</CarouselItemContentContainer>
    )}
  </CarouselItemContainer>
)

interface HeroImageCarouselProps {
  slides: HeroImageCarouselItem[]
  scrollToContentId?: string
}

interface HeroImageCarouselItem {
  id: number
  largeImage?: string
  smallImage?: string
  title?: string
  infoBox?: ReactNode
}
