import styled, { css } from 'styled-components'

import { FireCarousel } from '@nx/fire/carousel'
import { Heading } from '@nx/fire/typography'

import { LotCard } from '../../common/LotCard'

export const StyledHeading = styled(Heading)`
  margin-top: 70px;
  margin-bottom: 20px;
`

export const StyledLotCard = styled(LotCard)``

export const StyledFireCarousel = styled(FireCarousel)`
  margin-bottom: 70px;

  ${StyledLotCard} {
    width: 100%;

    ${({ theme }) => css`
      ${theme.media.up.md} {
        width: 275px;
      }
    `}
  }
`
