import type { GetServerSidePropsContext } from 'next'
import getConfig from 'next/config'

import { AlgoliaAuction } from '@nx/algolia'
import { RegionType } from '@nx/api'
import { getCookiesFromApp } from '@nx/cookies'
import { getDateNowUtc } from '@nx/dates'
import {
  LD_ID_COOKIE_NAME,
  LaunchDarklyFeatureFlags,
} from '@nx/external/launch-darkly-common'
import { getLaunchDarklyFlagServer } from '@nx/external/launch-darkly-server'
import { HeroCarouselItemsResponse } from '@nx/get-carousel-items'
import {
  AuctionBiddingStatus,
  AuctionLot,
  BonhamsCookies,
  Brand,
  EBrand,
  LotStatus,
  NewsAndVideosItem,
  SaleStatus,
} from '@nx/global-types'

import { CornetteHomePage } from '@web/components/brand/cornette/CornetteHomePage'
import { SkinnerHomePage } from '@web/components/brand/skinner/SkinnerHomePage'
import { HomePage } from '@web/components/home'
import {
  getAlgoliaAuctions,
  getAlgoliaAuctionsByObjectIDs,
  getAlgoliaLotsByObjectIDs,
} from '@web/helpers/api/algolia'
import { getCarouselItems } from '@web/helpers/api/home/getCarouselItems'
import { getNewsAndVideos } from '@web/helpers/api/home/getNewsAndVideos'
import { getPersonalisedRecommendations } from '@web/helpers/api/recommendation/crossingMinds/getPersonalisedRecommendations'
import { FeatureFlags } from '@web/helpers/enums'
import { getSiteBrand, withRedirects } from '@web/helpers/utils'
import { GlobalProps } from '@web/types/app'

const { serverRuntimeConfig } = getConfig()

export interface IndexProps {
  globalProps: GlobalProps
  carouselItems: HeroCarouselItemsResponse
  auctions: bonhamsAuctions | cornetteAuctions | skinnerAuctions
  lots?: AuctionLot[][]
  regionCounts?: Partial<{ [key in RegionType]: number }> | null
  newsAndVideos: NewsAndVideosItem[]
}

interface bonhamsAuctions {
  active: AlgoliaAuction[]
  recommended: AlgoliaAuction[]
}

interface cornetteAuctions {
  cornette: AlgoliaAuction[]
}

interface skinnerAuctions {
  skinner: AlgoliaAuction[]
  bonhams: AlgoliaAuction[]
}

export function Index(props: IndexProps) {
  switch (props.globalProps.brand) {
    case Brand.skinner:
      return (
        <SkinnerHomePage
          {...props}
          auctions={props.auctions as skinnerAuctions}
        />
      )
    case Brand.cornette:
      return (
        <CornetteHomePage
          {...props}
          auctions={props.auctions as cornetteAuctions}
        />
      )
    default:
      return (
        <HomePage
          {...props}
          userIp={props.globalProps.user?.ip}
          auctions={props.auctions as bonhamsAuctions}
        />
      )
  }
}

async function indexServerSideProps(context: GetServerSidePropsContext) {
  context.req.cookies = {
    ...getCookiesFromApp(context.res),
    ...context.req.cookies,
  }

  const cookies = context.req.cookies

  const brand = getSiteBrand(
    context.req.headers.host,
    cookies[FeatureFlags.SITE_BRAND] as EBrand
  )

  const fetchedProps =
    brand === Brand.skinner
      ? await fetchSkinnerData()
      : brand === Brand.cornette
        ? await fetchCornetteData()
        : await fetchBonhamsData(context)

  return {
    props: fetchedProps,
  }
}

const fetchBonhamsData = async (
  pageContext: GetServerSidePropsContext
): Promise<Omit<IndexProps, 'globalProps'>> => {
  const cookies = pageContext.req.cookies

  const { default: Cookies } = await import('cookies')
  const cookieJar = new Cookies(pageContext.req, pageContext.res)
  const isCrossingMindsEnabled = await getLaunchDarklyFlagServer(
    {
      ldId: cookieJar.get(LD_ID_COOKIE_NAME),
      sdkKey: serverRuntimeConfig.LAUNCHDARKLY_SDK_KEY,
    },
    LaunchDarklyFeatureFlags.FEATURE_CROSSING_MINDS
  )

  const auctionQuery = getAlgoliaAuctions({
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    numericFilters: ['lots.total > 0'],
    futureOnly: true,
    hitsPerPage: 12,
    brand: [
      Brand.bonhams,
      Brand.skinner,
      Brand.cornette,
      Brand.cars,
      Brand.bukowskis,
      Brand.bruunRasmussen,
    ],
    facets: ['hits', 'region.code'],
  })

  const [
    auctions,
    [recommendedAuctions, recommendedAuctionIds],
    [recommendedLots, recommendedLotIds],
    carouselItems,
    newsAndVideos,
  ] = await Promise.all([
    auctionQuery,
    isCrossingMindsEnabled
      ? fetchBonhamsRecommendedAuctions(cookies)
      : Promise.resolve([[], []]),
    isCrossingMindsEnabled
      ? fetchBonhamsRecommendedLots(cookies)
      : Promise.resolve([[], []]),
    getCarouselItems(Brand.bonhams),
    getNewsAndVideos(Brand.bonhams),
  ])

  const oneHourInMilliseconds = 60 * 60 * 1000

  cookieJar.set('rec_auctions', recommendedAuctionIds.join(','), {
    path: '/',
    maxAge: oneHourInMilliseconds,
    httpOnly: false,
  })
  cookieJar.set('rec_lots', recommendedLotIds.join(','), {
    path: '/',
    maxAge: oneHourInMilliseconds,
    httpOnly: false,
  })

  return {
    carouselItems,
    auctions: {
      active: auctions.hits,
      recommended: recommendedAuctions,
    },
    lots: [recommendedLots],
    newsAndVideos: newsAndVideos.results,
    regionCounts:
      auctions.facets && auctions.facets['region.code']
        ? auctions.facets['region.code']
        : null,
  }
}

const fetchSkinnerData = async (): Promise<Omit<IndexProps, 'globalProps'>> => {
  const skinnerQuery = getAlgoliaAuctions({
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    numericFilters: ['lots.total > 0'],
    brand: Brand.skinner,
    futureOnly: true,
    hitsPerPage: 12,
  })

  const moreAuctionsQuery = getAlgoliaAuctions({
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    auctionStatus: SaleStatus.ready,
    futureOnly: true,
    hitsPerPage: 12,
    brand: [Brand.bonhams, Brand.cornette, Brand.cars],
  })

  const [skinner, moreAuctions, carouselItems, newsAndVideos] =
    await Promise.all([
      skinnerQuery,
      moreAuctionsQuery,
      getCarouselItems(Brand.skinner),
      getNewsAndVideos(Brand.skinner),
    ])

  return {
    carouselItems: carouselItems,
    auctions: { skinner: skinner.hits, bonhams: moreAuctions.hits },
    newsAndVideos: newsAndVideos.results,
  }
}

const fetchCornetteData = async (): Promise<
  Omit<IndexProps, 'globalProps'>
> => {
  const cornetteQuery = getAlgoliaAuctions({
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    numericFilters: ['lots.total > 0'],
    brand: Brand.cornette,
    futureOnly: true,
    hitsPerPage: 12,
  })

  const [cornetteAuctions, carouselItems, newsAndVideos] = await Promise.all([
    cornetteQuery,
    getCarouselItems(Brand.cornette),
    getNewsAndVideos(Brand.cornette),
  ])

  return {
    carouselItems: carouselItems,
    auctions: { cornette: cornetteAuctions.hits },
    newsAndVideos: newsAndVideos.results,
  }
}

const fetchBonhamsRecommendedAuctions = async (
  cookies: BonhamsCookies & Partial<Record<string, string>>
) => {
  let recommendedAuctions: AlgoliaAuction[] = []
  let recommendedAuctionsIDs: string[] | number[]

  if (cookies['rec_auctions']) {
    recommendedAuctionsIDs = cookies['rec_auctions'].split(',')
  } else {
    recommendedAuctionsIDs = await getPersonalisedRecommendations(
      'auctions',
      cookies.bonhams_sid ?? cookies.xm_id
    )
  }

  if (recommendedAuctionsIDs.length) {
    const recommendedAuctionsResponse = await getAlgoliaAuctionsByObjectIDs({
      objectIDs: recommendedAuctionsIDs,
    })

    recommendedAuctions = recommendedAuctionsResponse.results
      ?.filter(Boolean)
      ?.filter(
        (auction) =>
          auction.auctionStatus === SaleStatus.ready &&
          auction.dates.end.timestamp > getDateNowUtc().seconds
      )
      ?.slice(0, 4)
  }

  return [recommendedAuctions, recommendedAuctionsIDs] as const
}

const fetchBonhamsRecommendedLots = async (
  cookies: BonhamsCookies & Partial<Record<string, string>>
) => {
  let recommendedLots: AuctionLot[] = []
  let recommendedLotIDs: string[] | number[]

  if (cookies['rec_lots']) {
    recommendedLotIDs = cookies['rec_lots'].split(',')
  } else {
    recommendedLotIDs = await getPersonalisedRecommendations(
      'lots',
      cookies.bonhams_sid ?? cookies.xm_id
    )
  }

  if (recommendedLotIDs.length) {
    const recommendedLotsResponse = await getAlgoliaLotsByObjectIDs({
      objectIDs: recommendedLotIDs,
    })

    recommendedLots = recommendedLotsResponse.results
      ?.filter(Boolean)
      ?.filter((lot) => lot.status === LotStatus.new)
  }

  return [recommendedLots, recommendedLotIDs] as const
}

export const getServerSideProps = withRedirects(indexServerSideProps)

export default Index
