import { AuctionLot } from '@nx/global-types'
import { useUserInteraction } from '@nx/utils/google-tag-manager'

import { mapAlgoliaLotToLotCardItem } from '../../../helpers/api'
import {
  StyledFireCarousel,
  StyledHeading,
  StyledLotCard,
} from './HomePageLots.styles'

export function HomePageLots({ title, lots }: HomePageLotsProps) {
  const { recordInteraction } = useUserInteraction()

  return (
    <>
      <StyledHeading $level={3} as="h2">
        {title}
      </StyledHeading>
      <StyledFireCarousel
        fullWidthItems="mobileOnly"
        isDesktopOnly
        alignment="baseline"
      >
        {lots.map((lot, index) => {
          return (
            <StyledLotCard
              key={`${lot.lotUniqueId}-${index}`}
              isShowingLotId={false}
              isFollowButtonDisplayed={false}
              lot={mapAlgoliaLotToLotCardItem(lot)}
              showBidNow
              showRegisterNow
              lotClickCallback={() => {
                recordInteraction({
                  action: 'lot-card.click',
                  category: 'Home Page - Bid Now',
                  label: `${lot.auctionId}-${lot.lotId}`,
                })
              }}
            />
          )
        })}
      </StyledFireCarousel>
    </>
  )
}

interface HomePageLotsProps {
  title: string
  lots: AuctionLot[]
}
