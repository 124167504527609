import styled, { css } from 'styled-components'

import { FireImage } from '@nx/fire/image'
import { FireLink, FireLinkProps } from '@nx/fire/link'
import {
  Body,
  BodyProps,
  Heading,
  HeadingProps,
  Label,
  LabelProps,
} from '@nx/fire/typography'

export const StyledHeading = styled(Heading)<HeadingProps>`
  margin-bottom: 15px;
`

export const Flex = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  gap: 20px;
`

export const Auction = styled(FireLink)<FireLinkProps>`
  ${({ theme }) => css`
    background-color: ${theme.colours.primary.white};
    border-radius: 3px;

    &:hover,
    &:focus {
      text-decoration: none;

      ${CTA} {
        text-decoration: underline;
      }
    }
  `}
`

export const AuctionDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
`

export const AuctionDetails = styled.div`
  padding: 10px 15px 10px 0;
  align-self: center;
`

export const Title = styled(Body)<BodyProps>`
  ${({ theme }) => theme.fontStyles.sourceSans.medium}
  margin: 0;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CTA = styled(Label)<LabelProps>`
  color: ${({ theme }) => theme.colours.primary.primary};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
`

export const Image = styled(FireImage)<{ $coverImage: boolean }>`
  height: 100%;
  width: 75px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  aspect-ratio: 1/1;

  ${({ $coverImage }) => css`
    object-fit: ${$coverImage ? 'cover' : 'contain'};
  `}
`

export const ImageContainer = styled.div`
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
`
