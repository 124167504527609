import * as polished from 'polished'
import styled, { css } from 'styled-components'

import { StyledArrowButton, StyledPaginationBlock } from '@nx/fire/carousel'
import { FireImage } from '@nx/fire/image'

export const HeroImageCarouselContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colours.primary.white};
    box-shadow: ${theme.shadows.large};
    position: relative;
    width: 100%;

    ${theme.media.up.lg} {
      box-shadow: none;
    }

    ${StyledPaginationBlock} {
      bottom: 0;
      grid-template-columns: 1fr;
      margin: 0;
      min-height: 30px;
      position: absolute;
      width: 100%;

      ${theme.components?.HeroImageCarousel?.Bullets};

      ${StyledArrowButton} {
        bottom: 80vw;
        height: 50px;
        left: 0;
        position: absolute;
        right: auto;
        width: 50px;

        &:first-of-type {
          ${theme.components?.HeroImageCarousel?.PrevArrow};
        }

        &:last-of-type {
          left: auto;
          right: 0;

          ${theme.components?.HeroImageCarousel?.NextArrow};
        }

        svg {
          height: 50px;
          width: 50px;
        }

        ${theme.media.up.md} {
          bottom: 40vw;
        }

        ${theme.media.up.lg} {
          bottom: 0;
          height: 40vw;
          max-height: 576px;
        }
      }
    }
  `}
`

export const ScrollToBottomContainer = styled.div`
  ${({ theme }) => css`
    display: none;

    ${theme.media.up.lg} {
      position: absolute;
      background-color: ${polished.rgba(theme.colours.primary.charcoal, 0.25)};
      z-index: ${theme.zIndex.slider};
      bottom: 0;
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;

      a {
        color: ${theme.colours.primary.white};
      }
    }
  `}
`

export const CarouselItemContainer = styled.div`
  position: relative;
  ${({ theme }) => theme.components?.HeroImageCarousel?.ItemContainer};
`

export const CarouselItemImageContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 100%;

    ${theme.media.up.md} {
      height: initial !important;
      padding-bottom: 40%;
    }

    ${theme.components?.HeroImageCarousel?.ImageContainer};
  `}
`

export const StyledSmallImage = styled(FireImage)`
  object-fit: cover;

  ${({ theme }) => theme.media.up.md} {
    display: none !important;
  }
`

export const StyledLargeImage = styled(FireImage)`
  display: none !important;

  ${({ theme }) => theme.media.up.md} {
    display: block !important;
    object-fit: cover;
  }
`

export const CarouselItemContentContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colours.primary.white};
    padding: 15px 15px 30px;

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    ${theme.media.up.lg} {
      background-color: rgb(255 255 255 / 90%);
      border-radius: 3px;
      bottom: 30px;
      box-shadow: ${theme.shadows.large};
      left: 30px;
      max-width: 565px;
      min-width: 430px;
      padding: 20px;
      position: absolute;
      width: auto;
    }

    ${theme.components?.HeroImageCarousel?.InfoBoxContainer};
  `}
`
